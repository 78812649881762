.wrapper {
  margin-top: 46px;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__column {

    &__left {
      padding-right: 30px;
    }

    &__right {
      padding-left: 30px;
    }
  }
}

.info {
  margin-bottom: 8px;

  &__tittle {
    padding-left: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: #373935;
    margin: 0;
  }

  &__block {
    min-height: 64px;
    background-color: #F0F3F6;
    padding: 16px 14px;

    &__field {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.3px;
      color: #373935;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
