@import '../../styles/config/config.scss';

.breadcrumb {
  padding: 0;
  margin: 0;
  margin-bottom: 4 * 5;

  &__title {
    display: inline-flex;
    color: $primary-text-color;
    line-height: 33px;
    font-weight: $fw-bold;
    margin-bottom: $base * 2;
  }

  &__right {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__source {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 12px;
    line-height: 20px;
    font-weight: $fw-medium;

    &__item>a {
      color: $grey-text-color;

      &__active {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #2e5596;
      }
    }

    &__item {
      color: $grey-text-color;

      &__active {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #373935;
      }
    }
  }
}
