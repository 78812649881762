.icon {
  &__block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__circle {
    margin-left: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &__check {
      color: #fff;
      margin-top: 5.5px;
      width: 10px;
      height: 5px;
      border-bottom: solid 2px currentColor;
      border-left: solid 2px currentColor;
      transform: rotate(-45deg);
    }

    &__item {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin-top: -2px;
    }
  }
}

.wrapper {
  background: #fff;
  box-shadow: 0px 2px 15px rgb(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px 30px;

  &__primary {
    padding: 0 50px
  }

  &__cuList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-between;

    &__child {
      min-width: 180px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: .2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #373935;
    }
  }

  &__tittle {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: #373935;
  }

  &__block {
    margin-top: 10px;

    &__text {
      margin: 0;
      padding: 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #373935;
    }
  }
}
