.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #F0F1F3;
}

.table::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #F0F1F3;
}

.table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #027DD9;
}

.table {
  display: block;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;

  &__loader {
    height: 100%;
    display: flex;
    min-height: 25vh;
    width: 100%;
    flex-direction: row;

  }

  &__pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__pages {
      margin: 0 8px;
      color: #bdbdbd;
    }
  }

  &__body {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #373935;
    border: 1px #EFF2F7;
    border-style: solid none;

    &__item {
      padding: 16px;

      &__link {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
        color: #2E5596;
      }
    }
  }

  &__header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #95A7CB;
    position: -webkit-sticky;
    position: sticky;
    background: white;
    top: -1px;

    &__item {
      padding: 16px;
      width: 10000px;
    }
  }
}
