.info {
  margin-bottom: 8px;

  &__tittle {
    padding-left: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: #373935;
    margin: 0;
  }

  &__block {
    min-height: 64px;
    background-color: #F0F3F6;
    padding: 16px 14px;

    &__field {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 36px;
      letter-spacing: 0.3px;
      color: #373935;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.list {
  list-style: none;
}

.fraction,
.top,
.bottom {
  padding: 0 5px;
}

.fraction {
  display: flex;
  text-align: center;
  line-height: 24px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #373935;
}

.result {
  padding-top: 13px;
  padding-left: 6px;
}

.bottom {
  border-top: 1px solid #373935;
  display: block;
}
