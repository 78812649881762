  .button {
    padding: 0;
    background: none;
    border: none;
  }

  .header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #373935;
      margin: 0;
    }
  }

  .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;

    &__icon {
      margin-left: auto;
      transition: transform 0.6s ease;
    }

    &__title {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
    }

    &__content {
      background-color: white;
      overflow: auto;
      transition: max-height 0.6s ease;

      &__noVisible {
        visibility: collapse;
      }
    }

    &__icon {
      margin-left: auto;
      transition: transform 0.6s ease;
    }

    &__section {
      display: flex;
      flex-direction: column;
    }
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: #ccc;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }
