.table {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__row {
      padding: 14px 20px;
      height: 100%;
      max-height: 50px;
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EFF2F7;

      &__text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #95A7CB;
      }
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__row {
      padding: 14px 20px;
      height: 100%;
      max-height: 50px;
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #EFF2F7;

      &__text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #373935;
      }
    }
  }

}
