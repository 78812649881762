.progress {
  &__wrapper {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 16px;
    background-color: hsl(0deg 0% 100%);
    z-index: 9999;
    top: 0px;
    right: 0;

    &__done {
      background-color: #52b36b;
      max-width: 100%;
      height: 100%;
      padding: 0 24px;
      transition: 0.5s ease 0.8s;

      &__label {
        color: #fff;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        margin: 0;

        &__dot__flashing {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #fff;
          color: #fff;
          animation: dotFlashing 1s infinite linear alternate;
          animation-delay: 0.5s;
        }

        &__dot__flashing::before,
        &__dot__flashing::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
        }

        &__dot__flashing::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #fff;
          color: #fff;
          animation: dotFlashing 1s infinite alternate;
          animation-delay: 0s;
        }

        &__dot__flashing::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #fff;
          color: #fff;
          animation: dotFlashing 1s infinite alternate;
          animation-delay: 1s;
        }
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #fff;
  }

  50%,
  100% {
    background-color: #281a5d;
  }
}
