.veterans {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: space-around;

  &__wrapper {
    margin-bottom: 12px;
  }

  &__label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #95a7cb;
  }

  &__value:hover {
    color: #2e5596;
  }

  &__value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #373935;
  }
}

.block {
  background: #fff;
  box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px 30px;
  display: flex;
}
