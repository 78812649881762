.header {
  display: flex;
  width: 100%;
  background: white;
  padding: 20px;
  justify-content: space-between;

  &__block {
    display: flex;
  }
}

.table {
  display: flex;

  &__pie {
    &__title {
      margin: 24px 0;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #373935;
    }
  }

  &__header {
    color: #2e5596;
  }

  &__column {
    &__name {
      color: #373935;
    }

    &__value {
      color: #373935;
    }

    color: #2e5596;
  }
}

.wrapper {
  background-color: #f5f5fb;
  padding: 20px;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &__block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      margin-top: 20px;
    }
  }
}

.block {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &__table {
    margin-top: 20px;
    padding: 20px 30px;

    &__pureBody {
      margin-top: 30px;
    }

    &__body {
      margin-top: 40px;
      height: 800px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__bar {
    margin-top: 20px;
    padding: 20px 30px;

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      &__title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #373935;
        margin: 0;
      }
    }

    &__body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0;
        padding-left: 0;

        &__title {
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #373935;
        }

        &__info {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          justify-content: space-evenly;

          &__item {
            padding: 0 10px;

            &__underline {
              border-bottom: 2px #f1f1f1 solid;
            }

            &__value {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: bold;
              font-size: 32px;
              line-height: 44px;
              display: flex;
              align-items: center;
              color: #373935;
              margin-bottom: 4px;
            }

            &__label {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.2px;
              color: #2e5596;
            }
          }
        }
      }
    }
  }

  &__chart {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px 30px 20px 9px;

    &__body {
      padding: 40px 0 0 20px;
    }
  }

  &__number {
    margin-top: 20px;
    padding: 20px 30px;

    &__body {
      display: flex;
      margin-top: 40px;
      margin-bottom: 30px;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0;

        &__value {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 44px;
          display: flex;
          align-items: center;
          color: #373935;
          margin: 0;
          width: 100%;
        }

        &__label {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #2e5596;
          margin: 4px 0 0 0;
        }
      }
    }
  }

  &__pie {
    padding: 20px 30px;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    &__body {
      width: 100%;
      margin-top: 60px;

      &__table {
        margin-top: 60px;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;

      &__title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #373935;
        margin: 0;
      }
    }
  }
}
